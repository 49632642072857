<template>
  <div>
    <b-card>
      <div class="row text-center ">
        <div class="col-sm-6 col-md-2">
          <b-form-datepicker
            v-model="startDate"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
            placeholder="start Date"
            block
            locale="en-US"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <b-form-datepicker
            v-model="endDate"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
            placeholder="end Date"
            block
            locale="en-US"
          />
        </div>
        <div class="col-sm-12 col-md-4">
          <b-form-group>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-2">
          <b-form-group>
            <b-form-select
              v-model="filterTerm"
              placeholder="Search"
              :options="options"
              class="d-inline-block"
            />
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-2">
          <b-button variant="primary" @click="handleDownload">
            <feather-icon icon="DownloadIcon" size="18" />
          </b-button>
        </div>
      </div>
    </b-card>
    <b-card>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="items"
        :isLoading="isDataLoading"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column -->
          <span
            v-if="
              props.column.field === 'status' ||
                props.column.field === 'paymentStatus'
            "
          >
            <b-badge
              :variant="statusBadgeVariant(props.row[props.column.field])"
            >
              {{ props.row[props.column.field] }}
            </b-badge>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10', '15']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BFormDatepicker,
  BAlert,
  BFormSelect,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import moment from "moment-timezone";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BCard,
    BButton,
    BFormDatepicker,
    BAlert,
    BFormSelect,
  },
  data() {
    return {
      items: [],
      reportdData: [],
      pageLength: 15,
      isDataLoading: true,
      startDate: moment()
        .subtract(3, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      fileType: "csv",
      fileName: "Booking-Report",
      columns: [
        {
          label: "Payment Status",
          field: "paymentStatus",
        },
        {
          label: "Booking No",
          field: "bookingId",
        },
        {
          label: "Booking Date",
          field: "bookingDate",
        },
        {
          label: "CheckIn Date",
          field: "checkIn",
        },
        {
          label: "CheckOut Date",
          field: "checkOut",
        },
        {
          label: "Region",
          field: "city",
        },
        {
          label: "Resort Name",
          field: "resortName",
        },
        {
          label: "Property Type",
          field: "propertyType",
        },
        {
          label: "Room Category",
          field: "roomCategory",
        },
        {
          label: "No of Rooms",
          field: "quantity",
        },
        {
          label: "Room Tarrif",
          field: "basePrice",
        },
        {
          label: "Extra Bed",
          field: "extraBed",
        },
        {
          label: "Discount Amount",
          field: "priceDiscount",
        },
        {
          label: "Booking Room Tarrif",
          field: "totalRoomTarrif",
        },
        {
          label: "Booking GST",
          field: "totalRoomTax",
        },
        {
          label: "Total Booking",
          field: "totalRoomRate",
        },
        {
          label: "Booking GST Percent",
          field: "gstPercentage",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Discount Category",
          field: "discountType",
        },
        {
          label: "Payment Gateway",
          field: "pg",
        },
        {
          label: "Payment ID",
          field: "paymentID",
        },
        {
          label: "Transaction Id",
          field: "_id",
        },
        {
          label: "original Transaction Id",
          field: "previousTxnId",
        },
        {
          label: "Payment Mode Name",
          field: "paymentMode",
        },
        {
          label: "Remark",
          field: "remark",
        },
        {
          label: "Origin",
          field: "origin",
        },
        {
          label: "Booking Financial Year",
          field: "financialYear",
        },
        {
          label: "User GSTIN",
          field: "userGstIn",
        },
        {
          label: "Passenger Name",
          field: "passengerName",
        },
        {
          label: "Booked By",
          field: "role",
        },
        {
          label: "Booked By Name",
          field: "userName",
        },
        {
          label: "Booked By Email",
          field: "userEmail",
        },
        {
          label: "Customer Phone",
          field: "passengerPhone",
        },
        {
          label: "Refund remark",
          field: "refundRemark",
        },
      ],
      searchTerm: "",
      filterTerm: {},
      filter: {},
      options: [
        { value: {}, text: "All Bookings" },
        { value: { propertyType: "Leased Property" }, text: "Leased Property" },
        { value: { propertyType: "MTDC Resort" }, text: "MTDC Resort" },
      ],
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    statusBadgeVariant(props) {
      switch (props) {
        case "Confirmed":
        case "Paid":
          return "light-success";
        case "Cancelled":
        case "Failed":
          return "light-danger";
        default:
          return "light-secondary";
      }
    },
    getHotels() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/reports/get-list`,
          {
            startDate: this.startDate,
            endDate: this.endDate,
            filter: this.filter,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.items = response.data.bookingReports;
          this.isDataLoading = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.isDataLoading = false;
        });
    },
    handleDownload() {
      if (!this.items.length) {
        this.makeToast("danger", "Error", `Data not found!`);
        return;
      }
      let content = this.createContent;
      // console.log("contentHandldownload", content);
      if (this.confirm !== null) {
        const result = confirm(this.confirm);
        if (!result) return;
      }

      const blob = new Blob([content], {
        type: `application/${this.fileType}`,
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${this.fileName}_${this.startDate}_${this.endDate}.${this.fileType}`;
      link.click();
      this.makeToast(
        "success",
        "Success",
        `Booking-Report has been downloaded successfully!`
      );
    },
  },
  watch: {
    startDate: function(newValue, oldValue) {
      // console.log(newValue, oldValue);
      if (newValue !== oldValue) {
        this.getHotels();
      }
    },
    endDate: function(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getHotels();
      }
    },
    filterTerm: function(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.filter = newValue;
        this.getHotels();
      }
    },
  },
  computed: {
    createContent() {
      let content = null;

      if (this.fileType === "json") {
        content = JSON.stringify(this.items);
      } else if (this.fileType === "csv") {
        const keys =
          this.reportdData.length > 0
            ? this.reportdData
            : Object.keys(this.items[0]);
        let csv = `\ufeff${keys.join()}\n`;

        for (let index = 0; index < this.items.length; index++) {
          const item = this.items[index];
          let line = keys
            .map((key) => {
              if (item[key] === null) {
                return null;
              } else if (typeof item[key] === "object") {
                return JSON.stringify([item[key]]);
              } else {
                return [item[key]];
              }
            })
            .join(",");
          csv += `${line}\n`;
        }
        content = csv;
      }
      return content;
    },
  },
  created() {
    this.getHotels();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.action-bar {
  display: flex;
}
</style>
